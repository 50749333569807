
const TSVn = [
    {
        value: null,
        name: 'SVtn',
        label: 'SV(tn)',
        type: 'number',
        help: 'Numero de siniestros viales por trimestre por nivel de pérdida: fatalidades, heridos graves con mas de 30 días de incapacidad, heridos leves con hasta 30 días de incapacidad, choques simples.',
        validations: 'required'
    },
    {
        value: null,
        name: 'K',
        label: 'K',
        type: 'number',
        help: 'Constante equivalente a 1\'000.000 de kilómetros.',
        validations: 'required'
    },
    {
        value: null,
        name: 'kmt',
        label: 'Km(t)',
        type: 'number',
        help: 'Numero de kilómetros recorridos por trimestre por toda la flota de vehículos de la organización.',
        validations: 'required'
    }

]


const SVn = [
    {
        value: null,
        name: 'CDSVtn',
        label: 'CDSV(tn)',
        type: 'number',
        help: 'Numero de colisiones con daños materiales por trimestre por nivel de pérdida.',
        validations: 'required'
    },
    {
        value: null,
        name: 'CISVtn',
        label: 'CISV(tn)',
        type: 'number',
        help: 'Numero de colisiones sin daños materiales por trimestre por nivel de pérdida.',
        validations: 'required'
    }
]


const RSVI = [
    {
        value: null,
        name: 'RIia',
        label: 'RI(ia)',
        type: 'number',
        help: 'Cantidad de riesgos identificados al inicio del año (total en matriz).',
        validations: 'required'
    },
    {
        value: null,
        name: 'RIfa',
        label: 'RI(fa)',
        type: 'number',
        help: 'Cantidad de riesgos identificados al final del año (total en matriz).',
        validations: 'required'
    },
]



const GRV = [
    {
        value: null,
        name: 'RVAia',
        label: 'RVAia',
        type: 'number',
        help: 'Cantidad de riesgos con valoración alta al inicio del año.',
        validations: 'required'
    },
    {
        value: null,
        name: 'RVAfa',
        label: 'RVAfa',
        type: 'number',
        help: 'Cantidad de riesgos con valoración alta al final del año.',
        validations: 'required'
    },
]

const CM_PESV = [
    {
        value: null,
        name: 'MAt',
        label: 'MA(t)',
        type: 'number',
        help: 'Numero de metas alcanzadas o logradas en el PESV por trimestre',
        validations: 'required'
    },
    {
        value: null,
        name: 'TMt',
        label: 'TM(t)',
        type: 'number',
        help: 'Numero total de metas definidas PESV por trimestre',
        validations: 'required'
    },
]


const CPlan_PESV = [
    {
        value: null,
        name: 'AEPlant',
        label: 'AEPlan(t)',
        type: 'number',
        help: 'Numero de actividades ejecutadas del plan anual de trabajo PESV por trimestre.',
        validations: 'required'
    },
    {
        value: null,
        name: 'APPlant',
        label: 'APPlan(t)',
        type: 'number',
        help: 'Numero total de actividades programadas del plan anual de trabajo PESV por trimestre.',
        validations: 'required'
    },
]


const EJLC = [
    {
        value: null,
        name: 'EJD',
        label: '#EJD',
        type: 'number',
        help: 'Numero de excesos en la jornada diaria de trabajo de los conductores (eventos en los que los conductores han superado el tiempo máximo permitido en la legislación) por mes.',
        validations: 'required'
    },
    {
        value: null,
        name: 'SDT',
        label: '#SDT',
        type: 'number',
        help: 'Sumatoria total de días trabajados por todos los conductores que realizan desplazamientos laborales por mes.',
        validations: 'required'
    },
]


const GVE = [
    {
        value: null,
        name: 'VIP',
        label: '#VIP',
        type: 'number',
        help: 'Vehículos incluidos en el programa de gestión de la velocidad (propios, contratistas y de terceros utilizados permanentemente para desplazamientos laborales) por mes.',
        validations: 'required'
    },
    {
        value: null,
        name: 'VDL',
        label: '#VDL',
        type: 'number',
        help: 'Numero de vehículos utilizados para desplazamientos laborales por mes.',
        validations: 'required'
    },
]


const ELVL = [
    {
        value: null,
        name: 'DLEV',
        label: '#DLEV',
        type: 'number',
        help: 'Nimero diario de desplazamientos laborales con exceso de velocidad (casos en los que se superé el limite definido por la organizacién) por mes.',
        validations: 'required'
    },
    {
        value: null,
        name: 'TDL',
        label: '#TDL',
        type: 'number',
        help: 'Numero total de desplazamientos laborales por mes.',
        validations: 'required'
    },
]


const IDP = [
    {
        value: null,
        name: 'VID',
        label: '#VID',
        type: 'number',
        help: 'Numero de vehículos inspeccionados diariamente.',
        validations: 'required'
    },
    {
        value: null,
        name: 'TV',
        label: '#TV',
        type: 'number',
        help: 'Numero total de vehículos que trabajan diariamente.',
        validations: 'required'
    },
]


const CPMVh = [
    {
        value: null,
        name: 'MEVht',
        label: 'MEVh(t)',
        type: 'number',
        help: 'Numero de actividades de mantenimiento preventivo ejecutadas por trimestre.',
        validations: 'required'
    },
    {
        value: null,
        name: 'MPVht',
        label: 'MPVh(t)',
        type: 'number',
        help: 'Numero total de actividades de mantenimiento preventivo programadas por trimestre.',
        validations: 'required'
    },
]


const CumPF_PESV = [
    {
        value: null,
        name: 'CESVt',
        label: 'CESV(t)',
        type: 'number',
        help: 'Numero de capacitaciones en seguridad vial ejecutadas por trimestre.',
        validations: 'required'
    },
    {
        value: null,
        name: 'CPSVt',
        label: 'CPSV(t)',
        type: 'number',
        help: 'Numero total de capacitaciones en seguridad vial programadas por trimestre.',
        validations: 'required'
    },
]


const CobPF_PESV = [
    {
        value: null,
        name: 'CFSVt',
        label: 'CFSV(t)',
        type: 'number',
        help: 'Numero de colaboradores de la organización capacitados en seguridad vial.',
        validations: 'required'
    },
    {
        value: null,
        name: 'CTt',
        label: 'CT(t)',
        type: 'number',
        help: 'Numero total colaboradores de la organización.',
        validations: 'required'
    },
]


const NCAC = [
    {
        value: null,
        name: 'NCI',
        label: '#NCI',
        type: 'number',
        help: 'Numero de no conformidades identificadas y analizadas.',
        validations: 'required'
    },
    {
        value: null,
        name: 'NCG',
        label: '#NCG',
        type: 'number',
        help: 'No conformidades gestionadas y cerradas.',
        validations: 'required'
    },
]


export default {
    TSVn,
    SVn,
    RSVI,
    GRV,
    CM_PESV,
    CPlan_PESV,
    EJLC,
    GVE,
    ELVL,
    IDP,
    CPMVh,
    CumPF_PESV,
    CobPF_PESV,
    NCAC
}